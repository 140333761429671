import PropTypes from 'prop-types'
import React from 'react'
import './quote-content-block.scss'

function QuoteContentBlock(props) {
    return (
        <div className="quote-content-block">
            <div className="quote">
                &ldquo;{props.data.quote.quote}&rdquo;
                <div className="author">{props.data.author}</div>
            </div>
        </div>
    )
}

QuoteContentBlock.propTypes = {
    data: PropTypes.shape({
        quote: PropTypes.shape({
            quote: PropTypes.string,
        }),
        author: PropTypes.string,
    }),
}

export default QuoteContentBlock
