import classNames           from 'classnames'
import Img                  from 'gatsby-image'
import Proptypes            from 'prop-types'
import React, { Component } from 'react'

import './image-content-block.scss'

class ImageContentBlock extends Component {
    render() {
        const { title, caption, image, altText, small } = this.props.data
        const imageClass = classNames({
            'image-content-block-small': small,
        })

        return (
            <div className={imageClass}>
                <div className="image-content-block">
                    <Img title={title} alt={altText} fluid={image.fluid} />
                    {caption && <div className="caption">{caption.caption}</div>}
                </div>
            </div>
        )
    }
}

ImageContentBlock.propTypes = {
    data: Proptypes.shape(({
        altText: Proptypes.string,
        caption: Proptypes.shape({
            caption: Proptypes.string,
        }),
        image: Proptypes.object,
        title: Proptypes.string,
        small: Proptypes.bool,
    })),
}

export default ImageContentBlock
