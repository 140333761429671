import BackgroundImage from 'gatsby-background-image'
import Button from './button'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { getSlug } from '../helpers/get-slug'
import PropTypes from 'prop-types'
import React from 'react'

import './hero-gallery.scss'

const HeroGallery = (props) => {
    const { slide } = props.data
    const { childContentfulSlideLeadingParagraphRichTextNode, title } = slide[0]

    function renderCTA() {
        const { callToActionReference, callToActionUrlLink } = slide[0]
        const link = callToActionReference && callToActionReference.internal.type && callToActionReference.internal.type && callToActionReference.slug ? getSlug(callToActionReference.internal.type, callToActionReference.slug) : callToActionUrlLink

        return (
            <Button to={link} className="primary inverted" id={slide[0].contentful_id}>{slide[0].callToAction}</Button>
        )
    }

    return (
        <div className="hero-gallery full-width">
            {
                slide.length > 0 &&
                <BackgroundImage
                    Tag="section"
                    className="slide"
                    fluid={slide[0].image.image.fluid}
                    backgroundColor="#040e18">
                    <div className="slide-content-wrapper">
                        <div className="slide-content constrain-width">
                            <div className="slide-content-inner">
                                <h2 className="slide-title">{title}</h2>
                                {documentToReactComponents(childContentfulSlideLeadingParagraphRichTextNode.json)}
                                {renderCTA()}
                            </div>
                        </div>
                    </div>
                </BackgroundImage>
            }
        </div>
    )
}

HeroGallery.propTypes = {
    data: PropTypes.shape({
        slide: PropTypes.array,
    }),
}

export default HeroGallery
