import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Helmet } from 'react-helmet'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import React from 'react'

import './team-content-block.scss'

const TeamContentBlock = (props) => {
    const { people } = props.data

    return (
        <div className="team-content-block">
            {
                people && people.length > 1 && people.map((person) => {
                    return (
                        <div className="team-content-block-person" key={person.id}>
                            <Helmet>
                                <script type="application/ld+json">{JSON.stringify([
                                    {
                                        '@context': 'http://schema.org',
                                        '@type': 'Person',
                                        name: person.name,
                                        jobTitle: person.position,
                                        email: person.email,
                                        telephone: person.phone,
                                        image: person.photo ? person.photo.fluid.src : null,
                                        description: person.about && person.about.json ? documentToPlainTextString(person.about.json) : null,
                                    },
                                ])}</script>
                            </Helmet>
                            { person.photo && <Img fluid={{ ...person.photo.fluid, aspectRatio: 1 }} />}
                            { person.name && <h3 className="name">{person.name}</h3>}
                            { person.email && <div className="email"><a href={`mailto:${person.email}`}>{person.email}</a></div>}
                            { person.position && <h5 className="position">{person.position}</h5>}
                            { person.phone && <div className="phone">Phone: {person.phone}</div>}
                            { person.about && person.about.json && <div className="about">{documentToReactComponents(person.about.json)}</div>}
                        </div>
                    )
                })
            }
        </div>
    )
}

TeamContentBlock.propTypes = {
    data: PropTypes.shape({
        people: PropTypes.array,
    }),
}

export default TeamContentBlock
