import classNames from 'classnames'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { getSlug } from '../helpers/get-slug'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { linkedContent } from '../helpers/linked-content-proptypes'
import PropTypes from 'prop-types'
import React from 'react'
import RenderContentBlocks from '../helpers/render-content-blocks'

import './content-set-block.scss'

const ContentSetBlockOneLeftTwoRight = (props) => {
    const { title, richTitle, linkedContent } = props.data
    const { hasSideBar } = props
    const wrapperClass = classNames('content-set-block-wrapper', {
        'full-width': !hasSideBar,
        'has-sidebar': hasSideBar,
    })

    // TODO: Use normal title if no rich title

    return (
        <div className="content-set-block one-left-two-right">
            <div className={wrapperClass}>
                <div className="content-set-block-inner constrain-width">
                    <h3 className="content-set-block-title hidden-desktop">{richTitle ? documentToReactComponents(richTitle.json) : title}</h3>
                    <div className="content-set-block-inner-section">
                        {linkedContent && linkedContent[0] &&
                            <Link className="item" to={getSlug(linkedContent[0].internal.type, linkedContent[0].slug)}>
                                {linkedContent[0].image && <Img fluid={{ ...linkedContent[0].image.image.fluid, aspectRatio: 2 }} />}
                                <div className="item-wrapper">
                                    {linkedContent[0].title && <h2 className="item-title">{linkedContent[0].title}</h2>}
                                    {linkedContent[0].contentBlocks && linkedContent[0].contentBlocks.length > 0 &&
                                    <div className="item-description">{RenderContentBlocks(linkedContent[0].contentBlocks)}</div>}
                                </div>
                            </Link>
                        }
                    </div>
                    <div className="content-set-block-inner-section right">
                        <h3 className="content-set-block-title hidden-mobile">{richTitle ? documentToReactComponents(richTitle.json) : title}</h3>
                        <div className="content-set-block-inner-section-inner-wrapper">
                            { linkedContent && linkedContent.length > 1 && linkedContent.map((content, index) => {
                                if (index > 0 && index < 3) {
                                    return (
                                        <Link className="item" to={getSlug(content.internal.type, content.slug)} key={index}>
                                            <div className="item-wrapper">
                                                {content.title && <h4 className="item-title">{content.title}</h4>}
                                                {content.contentBlocks && content.contentBlocks.length > 0 &&
                                                <div className="item-description">{RenderContentBlocks(content.contentBlocks)}</div>}
                                                <p className="item-read-more">Start your journey</p>
                                            </div>
                                        </Link>
                                    )
                                }
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

ContentSetBlockOneLeftTwoRight.propTypes = {
    hasSideBar: PropTypes.bool,
    data : PropTypes.shape({
        title: PropTypes.string,
        richTitle: PropTypes.shape({
            json: PropTypes.any,
        }),
        linkedContent: PropTypes.arrayOf(PropTypes.shape(linkedContent)),
    }),
}

export default ContentSetBlockOneLeftTwoRight
