import PropTypes from 'prop-types'

export const linkedContent = {
    title: PropTypes.string,
    category: PropTypes.string,
    slug: PropTypes.string,
    readingTime: PropTypes.number,
    internal: PropTypes.shape({
        type: PropTypes.string,
    }),
    image: PropTypes.shape({
        image: PropTypes.shape({
            fluid: PropTypes.shape(({
                aspectRatio: PropTypes.number,
                base64: PropTypes.string,
                sizes: PropTypes.string,
                src: PropTypes.string,
                srcSet: PropTypes.string,
            })),
        }),
    }),
    contentBlocks: PropTypes.array,
}
