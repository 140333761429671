import ContentSetBlock                from './content-set-block'
import DownloadSingleFileContentBlock from './download-single-file-content-block'
import EmbedVideo                     from './embed-video'
import HeroGallery                    from './hero-gallery'
import ImageContentBlock              from './image-content-block'
import ImpactMapContentBlock          from './impact-map-content-block'
import PropTypes                      from 'prop-types'
import QuoteContentBlock              from './quote-content-block'
import RichTextContentBlock           from './rich-text-content-block'
import TeamContentBlock               from './team-content-block'
import React, { Component, Fragment } from 'react'

class ContentBlocks extends Component {
    render() {
        const contentBlocks = this.props.data
        const hasSideBar = this.props.hasSideBar

        return (
            <Fragment>
                {
                    contentBlocks && contentBlocks.map((contentBlock, key) => {
                        if (contentBlock && contentBlock.internal && contentBlock.internal.type) {
                            switch (contentBlock.internal.type) {
                                case 'ContentfulImage':
                                    return <ImageContentBlock data={contentBlock} key={key}/>

                                case 'ContentfulRichTextContentBlock':
                                    return <RichTextContentBlock data={contentBlock} key={key}/>

                                case 'ContentfulHeroGallery':
                                    return <HeroGallery data={contentBlock} key={key}/>

                                case 'ContentfulContentSetContentBlock':
                                    return <ContentSetBlock data={contentBlock} key={key} hasSideBar={hasSideBar}/>

                                case 'ContentfulTeamContentBlock':
                                    return <TeamContentBlock data={contentBlock} key={key}/>

                                case 'ContentfulQuoteContentBlock':
                                    return <QuoteContentBlock data={contentBlock} key={key}/>

                                case 'ContentfulDownloadSingleFileContentBlock':
                                    return <DownloadSingleFileContentBlock data={contentBlock} key={key}/>

                                case 'ContentfulImpactMapContentBlock':
                                    return <ImpactMapContentBlock data={contentBlock} key={key}/>

                                case 'ContentfulEmbedVideoBlock':
                                    return <EmbedVideo data={contentBlock} key={key}/>

                                default:
                                    return null
                            }
                        }

                        return null
                    })
                }
            </Fragment>
        )
    }
}

ContentBlocks.propTypes = {
    data: PropTypes.array,
    hasSideBar: PropTypes.bool,
}

export default ContentBlocks
