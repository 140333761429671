import Button from './button'
import Icon from './icon'
import PropTypes from 'prop-types'
import React from 'react'

import './download-single-file-content-block.scss'

const DownloadSingleFileContentBlock = (props) => {
    const { title, fileTitle, fileDescription } = props.data
    const file = props.data.file.file.url

    return (
        <div className="download-single-file-content-block">
            <h3 className="heading-6 download-single-file-content-block-title">{title}</h3>
            <div className="download-single-file-content-block-inner">
                <div className="file-info-wrapper">
                    <h5 className="file-title">{fileTitle}</h5>
                    { fileDescription && <p className="file-description">{fileDescription.fileDescription}</p> }
                </div>
                <Button className="button primary inverted" to={file} target="_blank"><Icon name="download" title="download"/> Download</Button>
            </div>
        </div>
    )
}

DownloadSingleFileContentBlock.propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string,
        fileTitle: PropTypes.string,
        fileDescription: PropTypes.string,
        file: PropTypes.shape({
            file: PropTypes.shape({
                url: PropTypes.string,
            }),
        }),
    }),
}

export default DownloadSingleFileContentBlock
