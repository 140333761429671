import ContentSetBlockOneLeftTwoRight from './content-set-block-one-left-two-right'
import ContentSetBlockSingleRow from './content-set-block-single-row'
import PropTypes from 'prop-types'
import React from 'react'

import './content-set-block.scss'

const ContentSetBlock = (props) => {
    const hasSideBar = props.hasSideBar

    switch (props.data.template) {
        case ('Single Row') :
            return <ContentSetBlockSingleRow data={props.data} hasSideBar={hasSideBar}/>

        case ('1 Left 2 Right') :
            return <ContentSetBlockOneLeftTwoRight data={props.data} hasSideBar={hasSideBar}/>

        default:
            return <ContentSetBlockSingleRow data={props.data} hasSideBar={hasSideBar}/>
    }
}

ContentSetBlock.propTypes = {
    hasSideBar: PropTypes.bool,
    data: PropTypes.shape(({
        template: PropTypes.string,
    })),
}

export default ContentSetBlock
