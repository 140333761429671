import CategoryTag from './category-tag'
import classNames from 'classnames'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { linkedContent } from '../helpers/linked-content-proptypes'
import PropTypes from 'prop-types'
import React from 'react'
import RenderContentBlocks from '../helpers/render-content-blocks'

const ContentSetBlockSingleRow = (props) => {
    const { title, richTitle, linkedContent } = props.data
    const { hasSideBar } = props
    const wrapperClass = classNames('wrapper', {
        'full-width': !hasSideBar,
        'has-sidebar': hasSideBar,
    })

    return (
        <div className="content-set-block single-row">
            <h3 className="title">{richTitle ? documentToReactComponents(richTitle.json) : title}</h3>
            <div className={wrapperClass}>
                <div className="content-set-block-inner constrain-width">
                    {
                        linkedContent && linkedContent.length > 0 && linkedContent.map((content, key) => {
                            let slugPrefix

                            switch (content.internal.type) {
                                case ('ContentfulPage') :
                                    slugPrefix = ''
                                    break

                                case ('ContentfulResource') :
                                    slugPrefix = '/news'
                                    break

                                default:
                                    slugPrefix = ''
                            }

                            // TODO: Use normal title if no rich title
                            return (
                                <Link className={`item ${content.internal.type}`} to={`${slugPrefix}${content.slug === 'home' ? '/' : `/${content.slug}/`}`} key={key}>
                                    {content.image && <Img fluid={{ ...content.image.image.fluid, aspectRatio: 2 }} />}
                                    {content.category && <CategoryTag title={content.category}/>}
                                    <div className="item-wrapper">
                                        {content.title && <h4 className="item-title" title={content.title}>{content.title}</h4>}
                                        {content.contentBlocks && content.contentBlocks.length > 0 &&
                                            <div className="item-description">
                                                {RenderContentBlocks(content.contentBlocks)}
                                            </div>
                                        }
                                        {content.readingTime && <div className="item-reading-time">{content.readingTime} min read</div>}
                                    </div>
                                </Link>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

ContentSetBlockSingleRow.propTypes = {
    data : PropTypes.shape({
        title: PropTypes.string,
        richTitle: PropTypes.object,
        linkedContent: PropTypes.arrayOf(PropTypes.shape(linkedContent)),
    }),
    hasSideBar: PropTypes.bool,
}

export default ContentSetBlockSingleRow
