import classNames from 'classnames'
import Proptypes   from 'prop-types'
import React       from 'react'
import ReactPlayer from 'react-player'

import './embed-video.scss'

const EmbedVideo = (props) => {
    const { url, small } = props.data
    const videoClass = classNames({
        'embed-video-small': small,
    })

    return (
        <div className={ videoClass }>
            <div className="embed-video">
                <ReactPlayer
                    className="react-player"
                    url={url}
                    width="100%"
                    height="100%"
                    light={false}
                    controls={true}
                />
            </div>
        </div>
    )
}

export default EmbedVideo

EmbedVideo.propTypes = {
    data: Proptypes.shape(({
        url: Proptypes.string,
        small: Proptypes.bool,
    })),
}
