import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import './rich-text-content-block.scss'

class RichTextContentBlock extends Component {
    render() {
        const { body: { json }, title, showTitle } = this.props.data

        const options = {
            renderNode: {
                'table': (node, children) => (
                    <div className="contentful-table-container">
                        <table className="contentful-table">{children}</table>
                    </div>
                ),
                'table-row': (node, children) => <tr className="contentful-table-row">{children}</tr>,
                'table-header-cell': (node, children) => <th className="contentful-table-header">{children}</th>,
                'table-cell': (node, children) => <td className="contentful-table-cell">{children}</td>,
            },
        }

        return (
            <div className="rich-text-content-block">
                { showTitle && <h3 className="title">{title}</h3>}
                {documentToReactComponents(json, options)}
            </div>
        )
    }
}

RichTextContentBlock.propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string,
        showTitle: PropTypes.bool,
        body: PropTypes.shape({
            json: PropTypes.any,
        }),
    }),
}

export default RichTextContentBlock
