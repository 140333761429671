import BackgroundImage from 'gatsby-background-image'
import Button from './button'
import { getSlug } from '../helpers/get-slug'
import Img from 'gatsby-image'
import { numberWithCommas } from '../helpers/numbers'
import PropTypes from 'prop-types'
import React from 'react'

import './impact-map-content-block.scss'

const ImpactMapContentBlock = (props) => {
    const { backgroundImage, image, studentsImpacted, title, leadingParagraph, callToActionReference, callToActionUrlLink, callToAction } = props.data

    const link = callToActionReference && callToActionReference.internal.type && callToActionReference.internal.type && callToActionReference.slug ? getSlug(callToActionReference.internal.type, callToActionReference.slug) : callToActionUrlLink

    return (
        // TODO: Add itemProp (https://github.com/gatsbyjs/gatsby/tree/master/packages/gatsby-image#gatsby-image-props)
        <BackgroundImage
            Tag="div"
            className="impact-map-content-block full-width"
            fluid={backgroundImage.image.fluid}
            backgroundColor="#040e18">
            <div className="impact-map-content-block-wrapper">
                <div className="impact-map-content-block-inner constrain-width">
                    <div className="impact-map-content-block-section">
                        <div className="impact-map-content-block-section-inner">
                            <div className="num-impacted-title">Students impacted</div>
                            <div className="num-impacted-value">{numberWithCommas(studentsImpacted)}</div>
                            <h2 className="title">{title}</h2>
                            <p className="leading-paragraph">{leadingParagraph.leadingParagraph}</p>
                            <Button className="cta-button primary inverted transparent" to={link}>
                                {callToAction}
                            </Button>
                        </div>
                    </div>
                    <div className="impact-map-content-block-section">
                        <Img className="image" fluid={image.image.fluid} />
                    </div>
                </div>
            </div>
        </BackgroundImage>
    )
}

ImpactMapContentBlock.propTypes = {
    data: PropTypes.shape(({
        backgroundImage: PropTypes.shape({
            image: PropTypes.shape({
                fluid: PropTypes.shape(({
                    aspectRatio: PropTypes.number,
                    base64: PropTypes.string,
                    sizes: PropTypes.string,
                    src: PropTypes.string,
                    srcSet: PropTypes.string,
                })),
            }),
        }),
        image: PropTypes.shape({
            image: PropTypes.shape({
                fluid: PropTypes.shape(({
                    aspectRatio: PropTypes.number,
                    base64: PropTypes.string,
                    sizes: PropTypes.string,
                    src: PropTypes.string,
                    srcSet: PropTypes.string,
                })),
            }),
        }),
        studentsImpacted: PropTypes.number,
        title: PropTypes.string,
        leadingParagraph: PropTypes.shape(({
            leadingParagraph: PropTypes.string,
        })),
        callToActionReference: PropTypes.shape(({
            internal: PropTypes.shape(({
                type: PropTypes.string,
            })),
            slug: PropTypes.string,
        })),
        callToActionUrlLink: PropTypes.string,
        callToAction: PropTypes.string,
    })),
}

export default ImpactMapContentBlock
